import { Injectable, signal, WritableSignal } from '@angular/core';
import { Facet, VisibleFacet } from '@my-toolbox-fe/type-filter';
import { codesBrands, codesLanguages } from '@my-toolbox-fe/type-search';

type DecodedQuery = { code: string; values: [{ code: string; value: string }] };

@Injectable({
  providedIn: 'root',
})
export class FacetsService {
  facets: WritableSignal<Facet[]> = signal([]);
  selectedFacets: WritableSignal<VisibleFacet[]> = signal([]);

  setSelectedFacets(selectedFacets: VisibleFacet[]) {
    this.selectedFacets.set(selectedFacets);
  }

  updateSelectedFacets(selectedFacets: VisibleFacet[]) {
    this.selectedFacets.set([...this.selectedFacets(), ...selectedFacets]);
  }

  getSelectedFacets() {
    return this.selectedFacets().map((facet) => decodeURI(facet.value));
  }

  clearFacets() {
    this.setSelectedFacets([]);
  }

  setFacets(facets: Facet[]) {
    facets?.map((facet) => {
      facet.isExpanded = false;
      switch (facet.code) {
        case 'content_types':
          facet.code = 'Content Type';
          break;
        case 'language':
          facet.code = 'Language';
          facet.values = facet.values.map((facet) => {
            return {
              ...facet,
              textValue: codesLanguages[facet.value] || facet.value,
            };
          });
          break;
        case 'pim_product_company':
          facet.code = 'Product Company';
          break;
        case 'pim_product_range':
          facet.code = 'Product Range';
          break;
        case 'pim_product_model':
          facet.code = 'Product Model';
          break;
        case 'status':
          facet.code = 'Status';
          break;
        case 'brand':
          facet.code = 'Brand';
          facet.values = facet.values.map((facet) => {
            return {
              ...facet,
              textValue: codesBrands[facet.value] || facet.value,
            };
          });
          break;
        case 'asset_audience':
          facet.code = 'Asset Audience';
          break;
      }
      if (facet.values && facet.values.length > 5) {
        facet.visibleFacets = facet.values.slice(0, 5);
        return;
      }
      facet.visibleFacets = facet.values;
    });
    this.facets.set(facets);
  }

  updateSubscriptionFacets(
    facets: Facet[],
    decodedQuery: {
      facets: DecodedQuery[];
    },
  ) {
    facets.map((facet) => {
      facet.values.map((value) => {
        decodedQuery.facets.map((checkedFacet: DecodedQuery) => {
          if (checkedFacet.code === 'content_types') {
            checkedFacet.code = checkedFacet.values[0].code;
          }

          if (value.code === checkedFacet.code) {
            this.setSelectedFacets([...this.selectedFacets(), ...checkedFacet.values]);
          }
        });
      });
    });
  }
}
