export const codesCountries: { [key: string]: string } = {
  AL: 'Albania',
  DZ: 'Algeria',
  AO: 'Angola',
  AI: 'Anguilla AC',
  'ROOT-CC024-USE0-AI': 'Anguilla MB',
  AG: 'Antigua and Barbuda AC',
  'ROOT-CC024-USE0-AG': 'Antigua and Barbuda MB',
  AR: 'Argentina',
  AM: 'Armenia',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas AC',
  'ROOT-CC024-USE0-BS': 'Bahamas MB',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados AC',
  'ROOT-CC024-USE0-BB': 'Barbados MB',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda AC',
  'ROOT-CC024-USE0-BM': 'Bermuda MB',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia',
  BW: 'Botswana',
  BR: 'Brazil AC',
  'ROOT-CC019-BR80-BR': 'Brazil MB',
  VG: 'British Virgin Islands AC',
  'ROOT-CC024-USE0-VG': 'British Virgin Islands MB',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada AC',
  'ROOT-CC024-USE0-CA': 'Canada MB',
  KY: 'Cayman Islands AC',
  'ROOT-CC024-USE0-KY': 'Cayman Islands MB',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  CG: 'Congo Brazzaville',
  CD: 'Congo',
  CR: 'Costa Rica',
  CI: 'Ivory Coast',
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica AC',
  'ROOT-CC024-USE0-DM': 'Dominica MB',
  DO: 'Dominican Republic AC',
  'ROOT-CC024-USE0-DO': 'Dominican Republic MB',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Swaziland',
  ET: 'Ethiopia',
  FO: 'Faroe Islands',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada AC',
  'ROOT-CC024-USE0-GD': 'Grenada MB',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India AC',
  'ROOT-CC016-IN50-IN': 'India MB',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica AC',
  'ROOT-CC024-USE0-JM': 'Jamaica MB',
  JP: 'Japan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  XK: 'Kosovo',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LY: 'Libya',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  ML: 'Mali',
  MT: 'Malta',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  MX: 'Mexico',
  MD: 'Moldova',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat AC',
  'ROOT-CC024-USE0-MS': 'Montserrat MB',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  MK: 'North Macedonia',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PA: 'Panama',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico AC',
  'ROOT-CC024-USE0-PR': 'Puerto Rico MB',
  QA: 'Qatar',
  KR: 'South Korea',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  KN: 'Saint Kitts and Nevis AC',
  'ROOT-CC024-USE0-KN': 'Saint Kitts and Nevis MB',
  LC: 'Saint Lucia AC',
  'ROOT-CC024-USE0-LC': 'Saint Lucia MB',
  VC: 'Saint Vincent AC',
  'ROOT-CC024-USE0-VC': 'Saint Vincent MB',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SG: 'Singapore',
  SX: 'Sint Maarten AC',
  'ROOT-CC024-USE0-SX': 'Sint Maarten MB',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ZA: 'South Africa',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SE: 'Sweden',
  CH: 'Switzerland',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TT: 'Trinidad and Tobago AC',
  'ROOT-CC024-USE0-TT': 'Trinidad and Tobago MB',
  TN: 'Tunisia',
  TR: 'Türkiye',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands AC',
  'ROOT-CC024-USE0-TC': 'Turks and Caicos Islands MB',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'USA AC',
  'ROOT-CC024-USE0-US': 'USA MB',
  UY: 'Uruguay',
  VI: 'US Virgin Islands AC',
  'ROOT-CC024-USE0-VI': 'US Virgin Islands MB',
  UZ: 'Uzbekistan',
  VE: 'Venezuela',
  VN: 'Vietnam',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};
